<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Email Template Roles</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Email Template
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar"></div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="search.roles"
                    :items="roles"
                    label="Roles"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn @click="searchEmailTemplate" color="primary">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <table class="table">
                <thead>
                  <th>Title</th>
                </thead>
                <tbody>
                  <tr v-for="(role, index) of roles" :key="index">
                    <td>
                      <a @click="openEmailTemplateList(role.value)"
                        >{{ role.name }}
                        <v-icon class="green--text" x-small
                          >fas fa-circle dot-active
                        </v-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EmailTemplateService from "@/service/settings/email/EmailTemplateService";

const emailTemplate = new EmailTemplateService();

export default {
  name: "Roles",
  components: {},
  data() {
    return {
      email_templates: [],
      page: null,
      total: null,
      perPage: null,
      search: {
        active: "active",
        roles: ""
      },
      roles: []
    };
  },
  mounted() {
    this.getEmailTemplateRoles();
  },
  methods: {
    getEmailTemplateRoles() {
      emailTemplate.emailTemplateRoles().then(response => {
        this.roles = response.data;
      });
    },
    __edit(id) {
      this.$router.push({
        name: "email-template-update",
        params: { id: id }
      });
    },
    searchEmailTemplate() {
      this.getEmailTemplateRoles();
    },
    openEmailTemplateList(role) {
      this.$router.push({
        name: "email-template-role",
        params: { role: role }
      });
    }
  }
};
</script>
